.url-input {
  background: #ffffff;
  border: 1px solid var(--gray-400);
  border-radius: 1px;
  height: 40px;
  padding: 0 10px;
}

.query-input-here {
  padding: 12px 0;
}

.base-iri-input-here {
  padding: 0 0 12px 0;
}

sparql-editor {
  height: 349px;
}
